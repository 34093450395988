// https://owlcarousel2.github.io/OwlCarousel2/demos/demos.html
$('document').ready(function(){
	$('.carousel-default').owlCarousel({
		loop:true,
		autoplay:true,
		autoplayTimeout:3000,
		autoplaySpeed:1000,
		autoplayHoverPause: false,
		margin:20,
		nav:false,
		dots:false,
		responsive:{
			0:{
				items:1,
				stagePadding: 50,
			},
			576:{
				items:1,
				stagePadding: 120,
			},
			992:{
				items:2,
				stagePadding: 140,
			},
			2000:{
				items:3,
				stagePadding: 200,
			}
		}
	});
});
