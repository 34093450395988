$('document').ready(function(){

	//$('.page-loader').fadeOut();

	// Hamburger
	$('html').on('click', '.hamburger', function(event){
		event.preventDefault();
		$('body').addClass('nav-active');
		$('.fade-overlay').fadeIn(1000);
		if($(this).attr('aria-expanded')=='false') {
			$(this).attr('aria-expanded', 'true');
		} else {
			$(this).attr('aria-expanded', 'false');
		}
	});

	// Close nav
	$('html').on('click', '.close-nav, .fade-overlay', function(event){
		event.preventDefault();
		$('body').removeClass('nav-active');
		$('.fade-overlay').fadeOut(1000);
	});

	// Sub nav
	$('body').on('click', '.slidenav .menu-item-has-children > a:not(.menu-item-has-children li > a)', function(e){
		e.preventDefault();
		var navlabel = $(this).find('span').text();
		$(this).siblings('ul.sub-menu').find('.back-arrow').text(navlabel);
		$(this).siblings('ul.sub-menu').addClass('active');
		$('.mobile-navigation .button').hide();
	});
	$('.slidenav ul.sub-menu .back-arrow').click(function(){
		$(this).closest('ul.sub-menu').removeClass('active');
		$('.mobile-navigation .button').show();
	});

	// Floating Labels for CF7
	//https://webdesign.tutsplus.com/tutorials/floating-labels-for-contact-form-7--cms-36327
	const formsWithAnimatedLabels = document.querySelectorAll(".form-with-animated-labels");
	const focusedClass = "focused";

	for (const form of formsWithAnimatedLabels) {
		const formControls = form.querySelectorAll(
			'[type="text"], [type="email"], [type="tel"], textarea'
			);
		for (const formControl of formControls) {
			formControl.addEventListener("focus", function () {
				this.parentElement.nextElementSibling.classList.add(focusedClass);
			});

			formControl.addEventListener("blur", function () {
				if (!this.value) {
					this.parentElement.nextElementSibling.classList.remove(focusedClass);
				}
			});
		}

		form.parentElement.addEventListener("wpcf7mailsent", function () {
			const labels = document.querySelectorAll(".focused");
			for (const label of labels) {
				label.classList.remove(focusedClass);
			}
		});
	}

	// Parallax
	// Use 'data-speed' 0.0 to 1.0 for distance
	// Use 'data-direction' up or down for direction
	let parallaxElems = $("[data-speed]");

	$(parallaxElems).each(function () {
		let directionInit;
		let directionFinal;

		if (this.getAttribute("data-direction") == "up") {
			directionInit = -1;
			directionFinal = 1;
		} else if (this.getAttribute("data-direction") == "down") {
			directionInit = 1;
			directionFinal = -1;
		}

		gsap.to(this, {
			y: (i, el) =>
			directionInit * parseFloat(el.getAttribute("data-speed")) * this.offsetHeight
		});

		gsap.to(this, {
			y: (i, el) =>
			directionFinal *
			parseFloat(el.getAttribute("data-speed")) *
			this.offsetHeight,
			ease: "none",
			scrollTrigger: {
				trigger: this,
				start: "top bottom",
				end: "bottom top",
				invalidateOnRefresh: true,
				scrub: 0,
				markers: false
			}
		});
	});

	// BG Parallax
	let getRatio = el => window.innerHeight / (window.innerHeight + el.offsetHeight);

	gsap.utils.toArray(".bg-parallax").forEach((bgDiv, i) => {
		gsap.fromTo(bgDiv, {
			backgroundPosition: () => i ? `50% ${-window.innerHeight * getRatio(bgDiv)}px` : "50% 0px"
		}, {
			backgroundPosition: () => `50% ${window.innerHeight * (1 - getRatio(bgDiv))}px`,
			ease: "none",
			scrollTrigger: {
				trigger: bgDiv,
				start: () => i ? "top bottom" : "top top", 
				end: "bottom top",
				scrub: true,
				invalidateOnRefresh: true
			}
		});
	});
});
